import React, {useState} from 'react';
import './DecommissionSummary.css';
import { ButtonContainer } from '../common/buttonContainer/ButtonContainer';
import { Button, ButtonTheme } from '../common/button/Button';
import { EmptySpaceForFixedButtons } from '../common/emptySpaceForFixedButtons/EmptySpaceForFixedButtons';
import { homePageInitialState, useChatReasonContext, useHomePageContext } from '../../context/HomePageContext';
import {
  DecommissionScreenType,
  useDecommissionRequestContext,
  useDecommissionScreenContext
} from '../../context/DecommissionContext';
import {ScreenHeaderTitle} from '../common/screenHeader/ScreenHeaderTitle';
import {ConfirmModal} from '../common/confirmModal/ConfirmModal';

export type DecommissionSummaryProps = {
  deviceType: string;
  smetsDeviceType: string;
  deviceId: string;
  chfDeviceId: string;
  reason: string;
  mpxn: string;
}

export default function DecommissionSummary({
                                              deviceType,
                                              smetsDeviceType,
                                              deviceId,
                                              chfDeviceId,
                                              reason,
                                              mpxn,
                                            }: DecommissionSummaryProps) {
  const { setHomePageState } = useHomePageContext();
  const { setChatReason } = useChatReasonContext();
  const { setScreen } = useDecommissionScreenContext();
  const { setDecommissionRequest } = useDecommissionRequestContext();


  const [isConfirmModal, setIsConfirmModal] = useState(false)

  function cancelHandler() {
    setHomePageState({ ...homePageInitialState });
    setChatReason('');
    setIsConfirmModal(false);
  }

  function showConfirmModal() {
    setIsConfirmModal(true);
  }

  function modalConfirmHandler() {
    setIsConfirmModal(false);
    setDecommissionRequest({
      deviceType: deviceType,
      deviceId: deviceId,
      mpxn: mpxn,
    })
    setScreen(DecommissionScreenType.DECOMMISSION_LOADING);
  }

  function modalCancelHandler() {
    setIsConfirmModal(false);
  }

  // TODO the information headings look too big, need to reduce font size by creating a subheading class
  return <>
    { isConfirmModal && <ConfirmModal title={'This action cannot be undone'} modalText={'Are you sure you want to proceed?'} continueHandler={modalConfirmHandler} cancelHandler={modalCancelHandler} />
  }
    <ScreenHeaderTitle title={'You\'re ready to decommission this meter'} />
    <p className="m-b-10">This can't be undone. Please double-check these are the correct meter details before confirming the decommission.</p>
    <div id="decommission-summary-container" data-testid="decommission-summary-container">
      <div className="m-b-10">
        <p className="informationSubHeading">Meter to be decommissioned</p>
        <p>{deviceType}</p>
      </div>
      <div className="m-b-10">
        <p className="informationSubHeading">{deviceType} GUID</p>
        <p>{deviceId}</p>
      </div>
      <div className="m-b-10">
        <p className="informationSubHeading">{deviceType} Device Type</p>
        <p>{smetsDeviceType}</p>
      </div>
      <div className="m-b-10">
        <p className="informationSubHeading">GUID of attached CHF</p>
        <p>{chfDeviceId}</p>
      </div>
      <div className="m-b-10">
        <p className="informationSubHeading">Reason</p>
        <p>{reason}</p>
      </div>
    </div>
    <ButtonContainer>
      <Button buttonId="decommission-summary-start" buttonText="Decommission" buttonTheme={ButtonTheme.DANGER} isButtonEnabled={true} onClickHandler={showConfirmModal}/>
      <Button buttonId="decommission-summary-cancel" buttonText="Cancel" buttonTheme={ButtonTheme.SECONDARY} onClickHandler={cancelHandler}/>
    </ButtonContainer>
    <EmptySpaceForFixedButtons numberOfButtons={2}/>
  </>
}
