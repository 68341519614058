import React from 'react';
import Select, { SingleValue } from 'react-select';
import { ButtonContainer } from '../common/buttonContainer/ButtonContainer';
import { Button, ButtonTheme } from '../common/button/Button';
import { EmptySpaceForFixedButtons } from '../common/emptySpaceForFixedButtons/EmptySpaceForFixedButtons';
import {
  DecommissionReasonType, DecommissionScreenType,
  useDecommissionReasonContext,
  useDecommissionScreenContext,
} from '../../context/DecommissionContext';
import {homePageInitialState, useChatReasonContext, useHomePageContext} from '../../context/HomePageContext';
import './SelectDecommissionReason.css'
import {ScreenHeaderTitle} from '../common/screenHeader/ScreenHeaderTitle';

export default function SelectDecommissionReason() {
  const { setScreen } = useDecommissionScreenContext();
  const { decommissionReason, setDecommissionReason } = useDecommissionReasonContext();
  const { setChatReason } = useChatReasonContext();
  const { setHomePageState } = useHomePageContext();

  const options = [
    { value: DecommissionReasonType.MeterIsDamaged, label: DecommissionReasonType.MeterIsDamaged },
    { value: DecommissionReasonType.Complaint, label: DecommissionReasonType.Complaint },
    { value: DecommissionReasonType.NonComm, label: DecommissionReasonType.NonComm },
    { value: DecommissionReasonType.NoRoomForHotShoe, label: DecommissionReasonType.NoRoomForHotShoe },
    { value: DecommissionReasonType.NoCommsAfterPowerCycle, label: DecommissionReasonType.NoCommsAfterPowerCycle },
    { value: DecommissionReasonType.NonFaultyMeter, label: DecommissionReasonType.NonFaultyMeter },
  ]

  function selectReasonHandler(selectedDecommissionReason: SingleValue<{value: DecommissionReasonType, label: string}>) {
    if (selectedDecommissionReason) {
      setDecommissionReason(selectedDecommissionReason.value)
    }
  }

  function confirmHandler() {
    setScreen(DecommissionScreenType.DECOMMISSION_SUMMARY)
  }

  function cancelHandler() {
    setHomePageState({...homePageInitialState });
    setChatReason('');
  }

  return <>
    <ScreenHeaderTitle title={'Let us know why you\'re decommissioning this meter'} />
    <Select
      id="select-decommission-reason"
      placeholder="Select issue"
      className="basic-single"
      classNamePrefix="select"
      name="color"
      isSearchable={true}
      options={options}
      onChange={selectReasonHandler}
    />

    <ButtonContainer>
      <Button buttonId="decommission-reason-confirm" buttonText="Confirm" buttonTheme={ButtonTheme.PRIMARY} isButtonEnabled={!!decommissionReason} onClickHandler={confirmHandler}/>
      <Button buttonId="decommission-reason-cancel" buttonText="Cancel" buttonTheme={ButtonTheme.SECONDARY} isButtonEnabled={true} onClickHandler={cancelHandler}/>
    </ButtonContainer>
    <EmptySpaceForFixedButtons numberOfButtons={2}/>
  </>;
}
