import { apiClient } from './apiClient';

export const atomProxyCommsCheckClient = {
    generateXmlXrinv: async (request: any) => apiClient.post(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xrinv`, request),

    generateXmlXritm: async (request: any) => apiClient.post(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xritm`, request),

    generateXmlXrdlg: async (request: any) => apiClient.post(`${process.env.REACT_APP_SINGLE_GENERATE_XML_PROXY_LAMBDA_URL}/xrdlg`, request),

    getXmlXrinv: async (request: any) => apiClient.post(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xrinv`, request),

    getXmlXritm: async (request: any) => apiClient.post(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xritm`, request),

    getXmlXrdlg: async (request: any) => apiClient.post(`${process.env.REACT_APP_SINGLE_GET_XML_PROXY_LAMBDA_URL}/xrdlg`, request),
}

export const atomProxyDecommissionClient = {
    generateXml: async (request: any) => apiClient.post(`${process.env.REACT_APP_DECOMMISSION_GENERATE_XML_PROXY_LAMBDA_URL}`, request),

    getXml: async (request: any) => apiClient.post(`${process.env.REACT_APP_DECOMMISSION_GET_XML_PROXY_LAMBDA_URL}`, request),
}
