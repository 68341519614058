import React, {FC} from 'react';
import './ConfirmModal.css'

export interface ConfirmModalProps {
  title: string;
  modalText: string;
  continueHandler: () => void;
  cancelHandler: () => void;
}

export const ConfirmModal: FC<ConfirmModalProps> = ({title, modalText, continueHandler, cancelHandler}) => {
  return (
    <div className={'confirm-modal-container'}>
      <section className={'confirm-modal-main'} >
        <h1 className={'confirm-modal-title'}>{title}</h1>
        <p className={'confirm-modal-text'}>{modalText}</p>
        <div className={'confirm-modal-button-container'}>
          <button className={'confirm-modal-button confirm-modal-cancel-button'} onClick={cancelHandler}>Cancel</button>
          <button className={'confirm-modal-button confirm-modal-continue-button'} onClick={continueHandler}>Continue          </button>
        </div>
      </section>
    </div>
  );
}
