import React, { FC, Fragment } from 'react';
import './InformationPanel.css';

export interface InformationPanelProps {
  title: string | null;
  textArray: TextParagraph[];
  background: boolean;
  textAlign?: string;
  dataTestId?: string;
}

export interface TextParagraph {
  text: string;
  lineBreak: boolean;
}


  export enum TextAlign {
  CENTER = 'text-align-center',
  LEFT = 'text-align-left',
}


export const InformationPanel: FC<InformationPanelProps> = ({ title = null, textArray, background, textAlign = 'left', dataTestId }) => {

  function hasBackground() {
    return background ? ' informationPanelWithBackground' : ''
  }

  function alignText () {
    return textAlign === 'center' ? `${TextAlign.CENTER}` : `${TextAlign.LEFT}`
  }

  return (
    <div className={`informationPanelComponent ${hasBackground()} ${alignText()}`} data-testid={dataTestId ?? ''}>
      {title != null && <h1 className={'informationHeading'}>{title}</h1>}
      {textArray.map((textParagraph: TextParagraph, index: number) => {
        return (
          <Fragment key={index}>
            <p>{textParagraph.text}</p>
            {textParagraph.lineBreak && <br/>}
          </Fragment>
        )
      })}
    </div>
  );
}
