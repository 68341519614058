import { getAuthTokenOrLogOut } from '../components/util';

export const apiClient = {
  post: async (url: string, request: any) => {
    const userDetails = await getAuthTokenOrLogOut();
    request.headers.Authorization = `Bearer ${userDetails.token}`;

    return fetch(url, request);
  },
  get: async (url: string)=> {
    const userDetails = await getAuthTokenOrLogOut();

    const options = {
      headers: {
        Authorization: `Bearer ${userDetails.token}`
      }
    }

    return fetch(url, options);
  }
}

export function buildPostRequest(data: any) {
  return {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(data),
  };
}

export function buildGetRequest() {
  return {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
}
