import React from 'react';
import { InformationPanel, TextParagraph } from '../common/informationPanel/InformationPanel';
import { ScreenHeaderTitle } from '../common/screenHeader/ScreenHeaderTitle';
import { ButtonContainer } from '../common/buttonContainer/ButtonContainer';
import { Button, ButtonTheme } from '../common/button/Button';
import { useDecommissionResultContext } from '../../context/DecommissionContext';

export function DecommissionResult({ startChatHandler, finishHandler }: { startChatHandler: () => void, finishHandler: () => void }) {
  const { decommissionResult } = useDecommissionResultContext();

  const decommissionSuccessText: TextParagraph[] = [
    {
      text: decommissionResult.deviceId,
      lineBreak: false
    }
  ];

  const decommissionFailText: TextParagraph[] = [
    {
      text: 'Please contact SST',
      lineBreak: false
    }
  ]

  return (
    <>
      {decommissionResult.status === 200 ?
        <>
          <ScreenHeaderTitle title={'Meter decommissioned'}/>
          <p className="m-b-10">You can now remove this device:</p>
          <InformationPanel textArray={decommissionSuccessText} title={`${decommissionResult.deviceType} GUID`}
                            background={true}/>
          <ButtonContainer>
            <Button buttonId={'decommission-finish'} buttonText={'Finish'} buttonTheme={ButtonTheme.PRIMARY}
                    onClickHandler={finishHandler}></Button>
          </ButtonContainer>
        </>
        :
        <>
          <ScreenHeaderTitle title={'Decommission process failed'}/>
          <InformationPanel textArray={decommissionFailText} title={null} background={false} textAlign={'center'}/>
          <ButtonContainer>
            <Button buttonId={'decommission-result-start-chat'} buttonText={'Start Chat'}
                    buttonTheme={ButtonTheme.PRIMARY} onClickHandler={startChatHandler}></Button>
          </ButtonContainer>
        </>
      }
    </>
  );
}
