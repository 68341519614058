import React, { useEffect } from 'react';
import { InformationPanel, TextParagraph } from '../common/informationPanel/InformationPanel';
import { ScreenHeaderTitle } from '../common/screenHeader/ScreenHeaderTitle';
import {
  DecommissionScreenType,
  useDecommissionRequestContext,
  useDecommissionResultContext,
  useDecommissionScreenContext
} from '../../context/DecommissionContext';
import { atomProxyDecommissionClient } from '../../api/atomProxyClient';
import { buildPostRequest } from '../../api/apiClient';

export function DecommissionLoading() {
  const { setScreen } = useDecommissionScreenContext();
  const { setDecommissionResult } = useDecommissionResultContext();
  const { decommissionRequest } = useDecommissionRequestContext();

  const getXmlInitialTimeout = parseInt(`${process.env.REACT_APP_DECOMMISSION_GET_XML_INITIAL_TIMEOUT}`, 10);
  const getXmlTimeout = parseInt(`${process.env.REACT_APP_DECOMMISSION_GET_XML_TIMEOUT}`, 10);
  const maxRetries = parseInt(`${process.env.REACT_APP_DECOMMISSION_GET_XML_MAX_RETRIES}`, 10);
  let retries = 0;

  function getXml(request: any) {
    atomProxyDecommissionClient.getXml(request)
      .then(res => res.json())
      .then(res => {
        retries += 1;

        if (res.status === 200) {
          setDecommissionResult({
            status: res.status,
            deviceId: res.deviceId,
            deviceType: res.deviceType,
          })
          setScreen(DecommissionScreenType.DECOMMISSION_RESULT);
        } else {
          if (retries <= maxRetries) {
            setTimeout(() => getXml(request), getXmlTimeout)
          } else {
            // Timeout on decommission
            setDecommissionResult({
              status: 500,
              deviceId: '',
              deviceType: '',
            })
            setScreen(DecommissionScreenType.DECOMMISSION_RESULT);
          }
        }
      })
  }

  useEffect(() => {
    if (decommissionRequest.mpxn && decommissionRequest.deviceId && decommissionRequest.deviceType) {
      const request = buildPostRequest({
        deviceId: decommissionRequest.deviceId,
        deviceType: decommissionRequest.deviceType,
        mpxn: decommissionRequest.mpxn,
      });

      atomProxyDecommissionClient.generateXml(request)
        .then(res => res.json())
        .then(res => {
          const reqIdentifier = res.runId
          if (reqIdentifier) {
            setTimeout(() => getXml(
              buildPostRequest({'runId': reqIdentifier})
            ), getXmlInitialTimeout)
          }
        })
    }
  }, []);

  const decommissionInProgressText: TextParagraph[] = [
    {
      text: 'This normally takes around 3 minutes.',
      lineBreak: true
    },
    {
      text: 'If it takes more than 5 minutes, something may have gone wrong. The process will time out and you can speak to an agent.',
      lineBreak: false
    }
  ];
  return (
    <>
      <ScreenHeaderTitle title={'Decommission in progress'} />
      <InformationPanel textArray={decommissionInProgressText} title={null} background={true} />
    </>
  );
}
