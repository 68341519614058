import React, {FC} from 'react';
import './ScreenHeaderTitle.css'

export interface ScreenHeaderProps {
  title: string;
  dataTestId?: string
};


export const ScreenHeaderTitle: FC<ScreenHeaderProps> = ({title, dataTestId}) => {
  return (
    <div className={'screen-header-container'}>
      <h1 className={'screen-header-title'} data-testid={dataTestId ?? ''}>{title}</h1>
    </div>

  );
}
