import React, { FC } from 'react';
import { InformationPanel, TextParagraph } from '../common/informationPanel/InformationPanel';
import {ScreenHeaderTitle} from "../common/screenHeader/ScreenHeaderTitle";

export interface WaitingForResponseProps {
  dataTestId?: string;
}

const waitingForResponsePanelText: TextParagraph[] = [
  {
    text: 'This normally takes around 1 minute.',
    lineBreak: true
  },
  {
    text: 'If it takes more than 10 minutes, something may have gone wrong. The process will time out and show any results received.',
    lineBreak: true
  }
];

export const WaitingForResponse: FC<WaitingForResponseProps> = () => {
  return (
    <>
      {/* TODO add loading spinner here */}
      <ScreenHeaderTitle title={'Checking meter comms'} />
      <InformationPanel title={null} textArray={waitingForResponsePanelText} background={false} textAlign={'center'} dataTestId={'info-panel-loading'}/>
    </>
  );
}
