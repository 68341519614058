import {fetchAuthSession, signOut} from 'aws-amplify/auth';
import { buildPostRequest } from '../api/apiClient';

interface XrinvRequestData {
  xrinv_in_01: any
}

interface XritmRequestData {
  xritm_in_01: any
}

interface XrdlgRequestData {
  xrdlg_in_01: any
}

export function validateMpxn(mpxn: string): string {
  if (mpxn.length === 0) {
    return 'Error: Please enter the MPxN';
  }

  if (!/^\d{6,13}$/.test(mpxn)) {
    return 'Error: Please check the MPxN';
  }

  return '';
}

export function validateMsn(msn: string): string {
  if (msn.length === 0) {
    return 'Error: Please enter the MSN';
  }

  if (!/^[a-zA-Z0-9]{8,14}$/.test(msn)) {
    return 'Error: Please check the MSN';
  }

  return '';
}

export function buildXrinvRequest(mpxn: string): any {
  const xrinvRequestData: XrinvRequestData = {
    xrinv_in_01: {
      Identifier: mpxn,
    },
  };

  return buildPostRequest({ data: [xrinvRequestData] });
}

export function buildXritmRequest(mpxn: string, msn: string): any {
  const xritmRequestData: XritmRequestData = {
    xritm_in_01: {
      ImportMPxN: mpxn,
      MSN: msn,
    },
  };

  return buildPostRequest({ data: [xritmRequestData] });
}

export function buildXrdlgRequest(mpxn: string, chfGuid: string | undefined): any {
  const xrdlgRequestData: XrdlgRequestData = {
    xrdlg_in_01: {
      ImportMPxN: mpxn,
      Identifier: chfGuid,
    },
  };

  return buildPostRequest({ data: [xrdlgRequestData] });
}

export function makeReadableTimeStamp(readingTimeStamp: string | null | undefined) {
  if (!readingTimeStamp) {
    return '';
  }

  const date = new Date(readingTimeStamp);
  const options: Intl.DateTimeFormatOptions = {
    year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit',
  };
  return date.toLocaleDateString('en-GB', options).replace(/,/, ' ');
}

export function isMpxnValid(mpxn: string): boolean {
  if (mpxn.length === 0) {
    return false;
  }

  return /^\d{6,13}$/.test(mpxn);
}

export function getMpxnError(mpxn: string): string {
  if (mpxn.length === 0) {
    return 'Please enter the MPxN';
  }

  if (!/^\d{6,13}$/.test(mpxn)) {
    return 'Please check the MPxN';
  }

  return '';
}

export function isMsnValid(msn: string): boolean {
  if (msn.length === 0) {
    return false;
  }

  return /^[a-zA-Z0-9]{8,14}$/.test(msn);
}

export function getMsnError(msn: string): string {
  if (msn.length === 0) {
    return 'Please enter the MSN';
  }

  if (!/^[a-zA-Z0-9]{8,14}$/.test(msn)) {
    return 'Please check the MSN';
  }

  return '';
}

export const getAuthTokenOrLogOut = async () => {
  try {
    const session = await fetchAuthSession();
    if (!session.tokens) {
      console.error('Token has expired or an error occurred:');
      return { userEmail: null, token: null };
    }
    const token = session.tokens?.accessToken.toString() ?? null;
    const userEmail = session.tokens?.signInDetails?.loginId;
    return { userEmail, token };
  } catch (error) {
    console.error('Token has expired or an error occurred:', error);

    await signOut();
    console.log('User has been logged out');
    return { userEmail: null, token: null };
  }
};
